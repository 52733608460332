body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main{
  min-height: 80vh;
}

.mainpage-heading{
  font-weight: 900;
  font-size: 1.6rem;
  color: #a36e9b;
}

.footer-mainpage{
  background-color: #f1ecef;
}



.headfirst-img-edit{
  max-height: 80vh;
  border: #e58d4b;
}

.headfirst-img-instaview{
  max-height: 80vh;
}

.user-dropdown-profile{
  background-color: #9e899b !important;
 
  color: #fff !important;
  font-weight: 600 !important;
  margin-top: -0.5rem !important;
  padding: 1.5rem !important;
}

.lotus-bg{
  background-image: url('Lavender-Lotus-1.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}